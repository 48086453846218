<template>
  <div id="not-found-box">
    <div
      class="overlay text-white d-flex align-items-center justify-content-center"
    >
      <div class="text-center">
        <h1 class="display-4 text-white">This page does not exist.</h1>
        <p class="lead mt-4">And we're really sorry about that.</p>
        <router-link tag="button" to="/" class="btn btn-primary"
          >To homepage</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoFound",
};
</script>

<style scoped lang="scss">
#not-found-box {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-image: url(https://media0.giphy.com/media/dy4swYs1dp430jChRa/giphy.gif?cid=ecf05e47jrsj4mj8z6gox668c6flzyqbhv94dmb7jwc4m73n&rid=giphy.gif);
  .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.4);
  }
}
</style>